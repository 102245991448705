
a {
  color: white;
}

a:visited {
  color: white;
}

.App {
  text-align: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  overflow-wrap: break-word;
  /* height: 800px */
}

.videoBackground {
  position: absolute;
  /* width: 100%; */
  height: 100vh;
}

.videoBackground > * {
  height: 100%;
}

.mainBox {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-size: xx-large;
  transition: background-color 1s linear;
}

.mainBox * {
  margin: 0;
}

.mainCounter {
  font-family: 'Courier New', Courier, monospace;
  font-size: 70px;
  font-weight: bold;
}

.footerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;
  margin-bottom: 10px;
  font-size: 15px;
}